@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"), url(../fonts/Roboto-Regular.ttf) format("truetype");
}

body {
  letter-spacing: 0.01px !important;
  font-family: "Roboto-Regular" !important;
}

body h1 {
  font-weight: 300;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: #000;
  /* font-family: "Helvetica", Helvetica, Arial, sans-serif !important; */
}

body h3 {
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: italic;
  color: #555;
}

/* body a { */
  /* color: #06f; */
/* } */

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  /* border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto-Regular" !important;
  /* font-family: "Helvetica", Helvetica !important; */
  letter-spacing: 0.4px !important;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2),
.invoice-box table tr td:nth-child(3),
.invoice-box table tr td:nth-child(4) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

/* .invoice-box table tr.item:last-child {
  border-bottom: none !important;
} */

.invoice-box table tr.total td:nth-child(4) {
  border-top: 2px solid #eee;
  font-weight: bold;
}
/* 
@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
} */

.MMInfoSmallText {
  font-size: 13px;
}

.MMInfoSmallTextBR {
  margin: 1px;
}

.invoiceBilledToSmallText {
  font-size: 14px;
}
/* table {
  border: #000 solid 1px;
} */
