:root {
  --fc-border-color: hsl(0, 0%, 24%);
}
.fc-timegrid-slots td {
  height: 28px !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: 0px 0px 0px 0px #fff !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event:after,
.fc-timegrid-event.fc-event-mirror:after,
.fc-timegrid-more-link:after {
  content: none !important;
}
