.hover:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.05);
}

.hover:active {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.03);
}

.fc-toolbar-title {
  font-size: 1.3em !important;
}

/* fieldset[class*="MuiOutlinedInput-notchedOutline"] {
  border-width: 0px !important;
} */

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(../fonts/Roboto-Regular.ttf) format("truetype");
}

.invoiceContainer {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 272mm !important;
  width: 210mm !important;
  /* background: red; */
  padding: 107px 110px 110px 115px;
  font-family: "Roboto-Regular";
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 4px;
  margin-right: 5px;
}

.topInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-left: 2px;
}

.topLeftContainer {
  display: flex;
  flex-direction: column;
}

.topLeftSub {
  font-size: 11px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.5);
}

.topRightContainer {
  display: flex;
  flex-direction: column;
  margin-top: 86px;
  margin-right: 84px;
}

/* calendar styling */

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.fc-daygrid-event,
.fc-timegrid-event,
.fc-event-title {
  overflow: hidden;
}

.fc-toolbar-title {
  font-size: 1.4em !important;
}

/* .attachmentItem:hover{
  background:rgba(0,0,0,0.05)
} */

.fc-toolbar-title {
  display: inline;
  padding-top: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.MuiPaper-root,
.MuiPaper-elevation,
.MuiPaper-elevation5,
.css-1tehbvn-MuiPaper-root {
  box-shadow: none !important;
  /* box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
  0px 0px 0px 0px rgba(0, 0, 0, 0) !important; */
}

.DateRangePicker {
  z-index: 1;
}
.fc-timegrid-slot-minor {
  border-top-style: none !important;
}
